import { FragmentType, getFragmentData } from '@/lib/gql';
import { OperationStatus, SignRequestMetadataSourceType, TransactionMetadataSourceType } from '@/lib/gql/graphql';

import { isSignRequestSource, isTransactionSource, sourceConfigs } from './sources';
import { OperationGovernanceModelFragment } from './data-fragment';

/**
 * Namespace encapsulating all operation-related utilities and constants.
 */
export namespace Operation {
  /**
   * Mapping of source types to their display names.
   */
  const sourceDisplayNames: Record<string, string> = {};

  // Populate sourceDisplayNames by iterating over sourceConfigs
  for (const config of sourceConfigs) {
    sourceDisplayNames[config.key] = config.displayName;
  }

  /**
   * List of URL patterns associated with each source.
   */
  const sourceUrlPatterns: Array<{
    pattern: string;
    signSource: SignRequestMetadataSourceType;
    transactionSource: TransactionMetadataSourceType;
  }> = sourceConfigs.flatMap((config) =>
    config.patterns.map((pattern) => ({
      pattern,
      signSource: isSignRequestSource(config.key)
        ? (config.key as SignRequestMetadataSourceType)
        : SignRequestMetadataSourceType.WalletConnect, // Fallback or default value
      transactionSource: isTransactionSource(config.key)
        ? (config.key as TransactionMetadataSourceType)
        : TransactionMetadataSourceType.WalletConnect, // Fallback or default value
    })),
  );

  /**
   * List of operation statuses that are considered pending.
   */
  export const pendingStatuses = [OperationStatus.Presigning, OperationStatus.Voting, OperationStatus.Signing];

  /**
   * List of operation statuses that are considered final.
   */
  export const finalStatuses = [
    OperationStatus.Broadcasting,
    OperationStatus.Submitted,
    OperationStatus.Cancelled,
    OperationStatus.Completed,
    OperationStatus.Expired,
    OperationStatus.Failed,
    OperationStatus.Rejected,
  ];

  /**
   * Checks if the given status is pending.
   *
   * @param status - The operation status to check.
   * @returns True if the status is pending, else false.
   */
  export function isPending(status: OperationStatus): boolean {
    return pendingStatuses.includes(status);
  }

  /**
   * Checks if the given status is final.
   *
   * @param status - The operation status to check.
   * @returns True if the status is final, else false.
   */
  export function isDone(status: OperationStatus): boolean {
    return finalStatuses.includes(status);
  }

  /**
   * Checks if the operation was successful.
   *
   * @param status - The operation status to check.
   * @returns True if the operation was successful, else false.
   */
  export function wasSuccessful(status: OperationStatus): boolean {
    return status === OperationStatus.Completed || status === OperationStatus.Submitted;
  }

  /**
   * Determines whether the operation has failed.
   * Does not include rejected or expired status.
   *
   * @param status - The operation status to check.
   * @returns True if the operation failed, else false.
   */
  export function didFail(status: OperationStatus): boolean {
    return status === OperationStatus.Failed;
  }

  /**
   * Checks if the operation is in the state of being signed.
   *
   * @param status - The operation status to check.
   * @returns True if the operation is signing-related, else false.
   */
  export function isSigning(status: OperationStatus): boolean {
    return [OperationStatus.Signing, OperationStatus.Presigning, OperationStatus.Broadcasting].includes(status);
  }

  /**
   * Retrieves the display name of the source for the operation.
   *
   * @param source - The source type.
   * @returns The display name of the source, or an empty string if not found.
   */
  export function getSourceDisplayName(
    source: SignRequestMetadataSourceType | TransactionMetadataSourceType | null | undefined,
  ): string {
    if (!source) return '';

    // Provide a clear fallback and log potential missing mappings
    const displayName = sourceDisplayNames[source];

    if (!displayName) {
      console.warn(`No display name found for source: ${source}`);
      return source;
    }

    return displayName;
  }

  /**
   * Determines the source type from the given URL and operation type.
   *
   * @param url - The source URL.
   * @param type - The type of operation ('sign' or 'transaction').
   * @returns The corresponding source type.
   */
  export function getSourceFromSourceUrl(
    url: string | undefined,
    type: 'sign' | 'transaction',
  ): SignRequestMetadataSourceType | TransactionMetadataSourceType {
    const defaultSource = type === 'sign'
      ? SignRequestMetadataSourceType.WalletConnect
      : TransactionMetadataSourceType.WalletConnect;

    if (!url) {
      return defaultSource;
    }

    const normalizedUrl  = url.toLowerCase().trim();
    const found = sourceUrlPatterns.find((entry) => normalizedUrl.includes(entry.pattern));

    if (found) {
      return type === 'sign' ? found.signSource : found.transactionSource;
    }

    return defaultSource;
  }

  export namespace Governance {
    export function getActionName(operationParam: FragmentType<typeof OperationGovernanceModelFragment>) {
      const op = getFragmentData(OperationGovernanceModelFragment, operationParam);
      const details = op.details.action.userManagementDetails;
      return details?.__typename;
    }
  }
}

'use client';
import { ReactNode, useState } from 'react';
import { CoreTypes } from '@walletconnect/types';

import { useWalletConnectState } from '@/features/wallet-connect/context';
import type { LoaderProps } from '@/features/web3-modals';
import { ThreatPrompt, VerifyInfoBox, ProjectInfoCard, ModalFooter } from '@/features/web3-modals';
import { ModalDialog } from '@/components/ModalDialog';

import styles from './ModalWrapper.module.scss';

type Props = {
  children: ReactNode;
  metadata: CoreTypes.Metadata;
  onApprove: () => void;
  onReject: () => void;
  intention?: string;
  infoBoxCondition?: boolean;
  infoBoxText?: string;
  approveLoader?: LoaderProps;
  rejectLoader?: LoaderProps;
  disableApprove?: boolean;
  disableReject?: boolean;
};

export function WalletConnectModalWrapper({
  children,
  metadata,
  onApprove,
  onReject,
  approveLoader,
  rejectLoader,
  intention,
  infoBoxCondition,
  infoBoxText,
  disableApprove,
  disableReject,
}: Props) {
  const { modal } = useWalletConnectState();
  const [threatAcknowledged, setThreatAcknowledged] = useState(false);

  const currentRequestVerifyContext = modal.data?.currentRequestVerifyContext;
  const isScam = currentRequestVerifyContext?.verified.isScam;
  const isDisplayMainContent = !isScam || threatAcknowledged;

  return (
    <ModalDialog
      isOpen={modal.isOpen}
      preventCancel
      hideCloseButton
      className={modal.view === 'SessionProposalModal' ? styles.modalSmall : styles.modalLarge}
      // Closing is handled by the reducer
      onClose={() => {}}
      footer={
        isDisplayMainContent && (
          <ModalFooter
            onApprove={onApprove}
            onReject={onReject}
            approveLoader={approveLoader}
            rejectLoader={rejectLoader}
            infoBoxCondition={infoBoxCondition}
            infoBoxText={infoBoxText}
            disableApprove={disableApprove}
            disableReject={disableReject}
          />
        )
      }
    >
      {isDisplayMainContent ? (
        <>
          <ProjectInfoCard metadata={metadata} intention={intention} />
          <div className={styles.divider} />
          <VerifyInfoBox />
          <div className={styles.container}>{children}</div>
        </>
      ) : (
        <ThreatPrompt metadata={metadata} onApprove={() => setThreatAcknowledged(true)} onReject={onReject} />
      )}
    </ModalDialog>
  );
}

import * as Sentry from '@sentry/nextjs';

import { FragmentType, getFragmentData } from '@/lib/gql';
import { ContractType } from '@/lib/gql/graphql';

import { Network } from '../Network';

import { AssetModelFragment } from './data-fragment';

export * from './data-fragment';

export namespace Asset {
  export function isNative(assetParam: FragmentType<typeof AssetModelFragment>): boolean {
    const data = getFragmentData(AssetModelFragment, assetParam);
    switch (data.details.contractType) {
      case ContractType.Native: {
        return true;
      }
      case ContractType.Erc20: {
        return false;
      }
      case ContractType.Trc20: {
        return false;
      }
      default: {
        throw new Error(`unrecognized "contractType": "${data.details.contractType}"`);
      }
    }
  }

  export function isIoNetwork(assetParam: FragmentType<typeof AssetModelFragment>): boolean {
    return getNetwork(assetParam) === Network.Type.IoNetwork;
  }

  export function getNetwork(assetParam: FragmentType<typeof AssetModelFragment>): Network.Type | null {
    const data = getFragmentData(AssetModelFragment, assetParam);
    const networkId = data.id.split(/(\.|__)/)[0];
    switch (networkId) {
      case 'ARBITRUM': {
        return Network.Type.Arbitrum;
      }
      case 'AVALANCHE': {
        return Network.Type.Avalanche;
      }
      case 'BASE': {
        return Network.Type.Base;
      }
      case 'BSC': {
        return Network.Type.BinanceSmartChain;
      }
      case 'BTC': {
        return Network.Type.Bitcoin;
      }
      case 'ETH': {
        return Network.Type.Ethereum;
      }
      case 'FANTOM': {
        return Network.Type.Fantom;
      }
      case 'IOCHAIN': {
        return Network.Type.IoNetwork;
      }
      case 'OPTIMISM': {
        return Network.Type.Optimism;
      }
      case 'POLYGON': {
        return Network.Type.Polygon;
      }
      case 'TRON': {
        return Network.Type.Tron;
      }
      case 'IOCHAIN_TESTNET': {
        return Network.Type.TestnetIoNetwork;
      }
      case 'BTC_TESTNET': {
        return Network.Type.TestnetBitcoin;
      }
      case 'ETH_SEPOLIA': {
        return Network.Type.TestnetSepoliaEthereum;
      }
      case 'ETH_HOLESKY': {
        return Network.Type.TestnetHoleskyEthereum;
      }
      default: {
        Sentry.captureMessage('unrecognized network ID', { extra: { networkId, assetId: data.id } });
        return null;
      }
    }
  }

  export function getContractAddress(assetParam: FragmentType<typeof AssetModelFragment>): string | null {
    if (isNative(assetParam)) {
      return null;
    }

    const data = getFragmentData(AssetModelFragment, assetParam);
    return data.details.contractAddress || null;
  }
}

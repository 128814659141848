import { User } from '@/lib/models';
import { LatestTermsSignature, TermsVersion } from '@/lib/gql/graphql';

export enum Roles {
  Admin = 'admin',
  AdminCompliance = 'admin_compliance',
  /** Graphql Roles Enum linked to 'UserRoles.Customer' */
  User = 'user',
  Readonly = 'read-only',
}

type ProductPermissions = { canAccess: boolean; canEdit: boolean };

export enum UserPlan {
  NetworkUC = 'io.network:uc',
  Platform = 'io.platform',
}

export enum Entitlements {
  Accounts = 'accounts',
  ChainsPrivate = 'chains-private',
  ChainsPublic = 'chains-public',
  Dapps = 'dapps',
  NetworkPM = 'network-pm',
  NetworkCompliance = 'network-compliance',
  Reports = 'reports',
  Users = 'users',
  Vaults = 'vaults',
}

/**
 * Mapping of UserPlan to TermsVersion
 * Updating the TermsVersion here will cause a retrigger of the terms and conditions form
 */

type ProductEntitlementsConfig = {
  [key in Entitlements]: {
    canEditIfRole: Roles[];
  };
};

const productEntitlementsConfig: ProductEntitlementsConfig = {
  [Entitlements.Accounts]: {
    canEditIfRole: [Roles.Admin],
  },
  [Entitlements.ChainsPrivate]: {
    canEditIfRole: [Roles.Admin],
  },
  [Entitlements.ChainsPublic]: {
    canEditIfRole: [Roles.Admin],
  },
  [Entitlements.Dapps]: {
    canEditIfRole: [Roles.Admin],
  },
  [Entitlements.NetworkCompliance]: {
    canEditIfRole: [Roles.AdminCompliance],
  },
  [Entitlements.NetworkPM]: {
    canEditIfRole: [Roles.Admin],
  },
  [Entitlements.Reports]: { canEditIfRole: [Roles.AdminCompliance] },
  [Entitlements.Users]: {
    canEditIfRole: [Roles.Admin, Roles.AdminCompliance],
  },
  [Entitlements.Vaults]: {
    canEditIfRole: [Roles.Admin],
  },
};

type Permissions = { [key in Entitlements]: ProductPermissions } & {
  // Permissions not modeled via entitlements
  governance: ProductPermissions;
  billing: ProductPermissions;
};

function validateAndAssignEntitlements(userEntitlements: string[], role: Roles): Permissions {
  const isAdmin = User.Role.isAdmin([role]);

  let permissions: Permissions = {
    [Entitlements.Accounts]: { canAccess: false, canEdit: false },
    [Entitlements.ChainsPrivate]: { canAccess: false, canEdit: false },
    [Entitlements.ChainsPublic]: { canAccess: false, canEdit: false },
    [Entitlements.Dapps]: { canAccess: false, canEdit: false },
    [Entitlements.NetworkCompliance]: { canAccess: false, canEdit: false },
    [Entitlements.NetworkPM]: { canAccess: false, canEdit: false },
    [Entitlements.Reports]: { canAccess: false, canEdit: false },
    [Entitlements.Users]: { canAccess: false, canEdit: false },
    [Entitlements.Vaults]: { canAccess: false, canEdit: false },
    billing: { canAccess: isAdmin, canEdit: isAdmin },
    governance: { canAccess: isAdmin, canEdit: isAdmin },
  };

  for (let key in productEntitlementsConfig) {
    const keyEntitlement = key as Entitlements;

    const entitlement = productEntitlementsConfig[keyEntitlement];
    const isNetworkCompliance = keyEntitlement === Entitlements.NetworkCompliance;

    if (isNetworkCompliance && userEntitlements.includes(Entitlements.NetworkPM)) {
      const isComplianceAdmin = User.Role.isAdminCompliance([role]);
      permissions[keyEntitlement] = { canAccess: isComplianceAdmin, canEdit: isComplianceAdmin };
    } else {
      const canAccess = userEntitlements.includes(key);
      const canEdit = canAccess && entitlement.canEditIfRole.includes(role);

      permissions[keyEntitlement] = { canAccess, canEdit };
    }
  }

  return permissions;
}

export type UserInfo = {
  id: string;
  org_id: string;
  email: string;
  family_name: string | null;
  given_name: string | null;
  initials: string | null;
  middle_name: string | null;
  name: string | null;
  picture: string | null;
  preferred_username: string | null;
  role: Roles;
  signedTerms: TermsVersion[] | null;
  productSignedTerms: LatestTermsSignature[] | null;
  plans: UserPlan[];
  hasSubscription?: 'false' | 'true' | boolean;
  permissions: Permissions;
};

export type JwtUserInfo = {
  sub: string;
  email: string;
  organisationId?: string | null | undefined;
  'custom:io:org_id'?: string | null | undefined;
  'custom:io:organisationId'?: string | null | undefined;
  initials?: string | null | undefined;
  family_name?: string | null | undefined;
  given_name?: string | null | undefined;
  middle_name?: string | null | undefined;
  preferred_username?: string | null | undefined;
  name?: string | null | undefined;
  picture?: string | null | undefined;
  roles?: string | null | undefined;
  entitlements?: string | null | undefined;
  productEntitlements?: string | null | undefined;
  signedTerms: TermsVersion[] | null | undefined;
  productSignedTerms: LatestTermsSignature[] | null | undefined;
  plans: string;
};

export function parseUserInfo(userInfo: JwtUserInfo): UserInfo {
  const orgEntitlements = userInfo.entitlements?.split(' ') ?? [];
  const role: Roles = User.Role.isAdminCompliance(userInfo.roles?.split(' ') ?? [])
    ? Roles.AdminCompliance
    : User.Role.isAdmin(userInfo.roles?.split(' ') ?? [])
    ? Roles.Admin
    : User.Role.isCustomer(userInfo.roles?.split(' ') ?? [])
    ? Roles.User
    : Roles.Readonly;

  // We should always add platform plan and then only add network uc plan if it exists
  const userPlans = [
    UserPlan.Platform,
    ...userInfo.plans
      .split(' ')
      .map((plan) => plan as UserPlan)
      .filter((plan) => {
        return plan === UserPlan.NetworkUC;
      }),
  ];

  return {
    id: userInfo.sub,
    org_id: userInfo.organisationId || userInfo['custom:io:organisationId'] || userInfo['custom:io:org_id'] || '',
    email: userInfo.email,
    family_name: userInfo.family_name || null,
    given_name: userInfo.given_name || null,
    initials: userInfo.initials || null,
    middle_name: userInfo.middle_name || null,
    name: userInfo.name || null,
    picture: userInfo.picture || null,
    preferred_username: userInfo.preferred_username || null,
    role,
    signedTerms: userInfo.signedTerms ?? null,
    plans: userPlans,
    hasSubscription: orgEntitlements.length > 0,
    productSignedTerms: userInfo.productSignedTerms ?? null,
    permissions: validateAndAssignEntitlements(orgEntitlements, role),
  };
}

import { forwardRef } from 'react';
import cx from 'classnames';

import { Link as IntlLink } from '@/lib/navigation';

import styles from './Link.module.scss';

type VariantProp = { variant: 'unstyled' | 'on-hover' | 'underline' };

type InternalLink = VariantProp &
  Omit<React.ComponentPropsWithoutRef<typeof IntlLink>, 'type' | 'legacyBehavior'> & {
    'data-testid'?: string;
  };
export const LinkInternal = forwardRef<HTMLAnchorElement, InternalLink>(function LinkInternalBase(
  { variant, 'data-testid': testId, ...props }: InternalLink,
  ref,
) {
  if (process.env.NODE_ENV === 'development') {
    if (props.passHref && variant !== 'unstyled') {
      throw new Error('variant="unstyled" should be used along with "passHref"');
    }

    if (props.passHref && !!props.className) {
      throw new Error('"className" does not take any effect when using "passHref"');
    }
  }

  return (
    <IntlLink
      {...props}
      className={cx(
        { [styles.underline]: variant === 'underline', [styles.onHover]: variant === 'on-hover' },
        props.className,
      )}
      legacyBehavior={!!props.passHref}
      data-testid={testId}
      ref={ref}
    />
  );
});

type ExternalLink = VariantProp &
  Omit<React.ComponentPropsWithoutRef<'a'>, 'type' | 'target' | 'rel'> & {
    'data-testid'?: string;
  };
export function LinkExternal({ variant, 'data-testid': testId, ...props }: ExternalLink) {
  return (
    <a
      {...props}
      className={cx(
        { [styles.underline]: variant === 'underline', [styles.onHover]: variant === 'on-hover' },
        props.className,
      )}
      target="_blank"
      rel="noreferrer"
      data-testid={testId}
    />
  );
}

type ModalLink = Omit<InternalLink, 'href' | 'hrefLang'>;
export function ModalLink({ variant, 'data-testid': testId, ...props }: ModalLink) {
  return (
    <a
      {...props}
      className={cx(
        { [styles.underline]: variant === 'underline', [styles.onHover]: variant === 'on-hover' },
        props.className,
      )}
      target="_blank"
      rel="noreferrer"
      data-testid={testId}
    />
  );
}

'use client';
import * as Sentry from '@sentry/nextjs';
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';

import { handleDisconnectActiveSessions } from '@/features/wallet-connect/services';
import { useLocalStorage } from '@/lib/hooks/browser';
import { WalletConnectModalProvider } from '@/features/web3-modals/wallet-connect';
import { useWeb3Wallet, useWeb3WalletEvents } from '@/features/wallet-connect/hooks';
import {
  WALLET_CONNECT_NAMESPACE,
  defaultChainId,
  defaultVault,
  defaultWalletConnectSettings,
  initialState,
} from '@/features/wallet-connect/utils';
import { UserInfo } from '@/lib/auth';

import { ActiveVault, State, reducer } from './wallet-connect-state';

type ContextType = State & {
  mutations: {
    setSettings: (settings: Partial<State['settings']>) => void;
    setIsUserDisconnect: (isUserDisconnect: boolean) => void;
    openModal: (view: State['modal']['view'], data: State['modal']['data']) => void;
    closeModal: () => void;
    toggleSidebar: () => void;
  };
};

const initialContext: ContextType = {
  ...initialState,
  mutations: {
    setSettings: () => null,
    setIsUserDisconnect: () => null,
    openModal: () => null,
    closeModal: () => null,
    toggleSidebar: () => null,
  },
};

type ProviderProps = {
  user: UserInfo;
  children: React.ReactNode;
};

const Context = createContext<ContextType>(initialContext);

export const useWalletConnectState = () => useContext(Context);

export function WalletConnectStateProvider({ user, children }: ProviderProps) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    settings: {
      ...initialState.settings,
      user,
    },
  });
  const { initialized, web3Wallet } = useWeb3Wallet(dispatch);
  useWeb3WalletEvents(web3Wallet, dispatch);

  const [, setActiveChainId] = useLocalStorage<string>(
    `${WALLET_CONNECT_NAMESPACE}.ACTIVE_CHAIN_ID`,
    state.settings.activeChainId,
  );
  const [, setActiveVault] = useLocalStorage<ActiveVault>(
    `${WALLET_CONNECT_NAMESPACE}.ACTIVE_VAULT`,
    state.settings.activeVault,
  );
  const [, setEip155Address] = useLocalStorage<string>(
    `${WALLET_CONNECT_NAMESPACE}.EIP155_ADDRESS`,
    state.settings.addresses.eip155Address,
  );

  const isConnected = useMemo(() => state.settings.sessions.length > 0, [state.settings.sessions.length]);
  const prevIsConnectedRef = useRef(isConnected);
  const hasRunOnceRef = useRef(false);

  useEffect(() => {
    const checkActiveVault = async () => {
      if (!initialized || !web3Wallet || hasRunOnceRef.current) return;

      if (isConnected && !state.settings.activeVault.id) {
        console.error('Active vault id is missing, disconnecting active session');
        await handleDisconnectActiveSessions(web3Wallet);
        dispatch({ type: 'set-settings', payload: defaultWalletConnectSettings });
        Sentry.captureMessage('Active vault id is missing, disconnecting active session');
      }

      hasRunOnceRef.current = true;
    };

    checkActiveVault();
  }, [initialized, isConnected, state.settings.activeVault.id, state.settings.sessions, state.web3Wallet, web3Wallet]);

  useEffect(() => {
    if (!initialized || !web3Wallet) return;
    dispatch({ type: 'set-web3-wallet', payload: { web3Wallet } });
    dispatch({ type: 'set-is-loading', payload: { isLoading: false } });
  }, [initialized, web3Wallet]);

  useEffect(() => {
    if (prevIsConnectedRef.current !== isConnected) {
      dispatch({ type: 'set-is-user-connected', payload: { isUserConnected: isConnected } });
      prevIsConnectedRef.current = isConnected;
    }
  }, [isConnected]);

  const toggleSidebar = useCallback(() => dispatch({ type: 'toggle-sidebar' }), []);

  const setSettings = useCallback(
    (settings: Partial<State['settings']>) => {
      setActiveChainId(settings.activeChainId ?? defaultChainId);
      setActiveVault(settings.activeVault ?? defaultVault);
      setEip155Address(settings.addresses?.eip155Address ?? '');
      dispatch({ type: 'set-settings', payload: settings });
    },
    [setActiveChainId, setActiveVault, setEip155Address],
  );

  const openModal = useCallback(
    (view: State['modal']['view'], data: State['modal']['data']) =>
      dispatch({ type: 'open-modal', payload: { view, data } }),
    [],
  );

  const closeModal = useCallback(() => dispatch({ type: 'close-modal' }), []);

  const setIsUserDisconnect = useCallback(
    (isUserDisconnect: boolean) => dispatch({ type: 'set-is-user-disconnect', payload: { isUserDisconnect } }),
    [],
  );

  const value = useMemo(
    () => ({
      ...state,
      mutations: {
        toggleSidebar,
        setSettings,
        setIsUserDisconnect,
        openModal,
        closeModal,
      },
    }),
    [state, toggleSidebar, setSettings, openModal, closeModal, setIsUserDisconnect],
  );

  return (
    <Context.Provider value={value}>
      <WalletConnectModalProvider>{children}</WalletConnectModalProvider>
    </Context.Provider>
  );
}

import React, { useMemo } from 'react';
import cx from 'classnames';
import Image from 'next/image';
import MersenneTwister from 'mersenne-twister';

import { Tooltip } from './Tooltip';
import { getSeedFromAnything } from './Jazzicon';
import styles from './Avatar.module.scss';

const colors = Array.from({ length: 7 }).map((_, index) => `var(--io-avatar-color-${index + 1})`);
const textColors = Array.from({ length: 7 }).map((_, index) => `var(--io-avatar-text-${index + 1})`);

export type AvatarProps = {
  src: string | null | undefined;
  userId: string;
  userInitials: string | null | undefined;
  userName: string | null | undefined;
  userRole: 'user' | 'admin' | 'admin_compliance';
  userType: 'human' | 'api';
  highlight?: 'danger' | 'primary' | 'dimmed' | null | undefined;
  required?: boolean;
  tooltip?: React.ReactNode;
};

type Props = AvatarProps & {
  className?: string;
  size: 'navBar' | 'small' | 'large' | 'huge';
};

export function Avatar({ tooltip, ...props }: Props) {
  if (tooltip) {
    // Do not render the title if there's a tooltip already
    return (
      <Tooltip content={tooltip}>
        <AvatarInner showTitle={false} {...props} />
      </Tooltip>
    );
  }

  return <AvatarInner showTitle {...props} />;
}

export function AvatarInner({
  className,
  userId,
  src,
  userInitials,
  userName,
  size,
  userType,
  userRole,
  highlight,
  required,
  showTitle = true,
}: Props & { showTitle: boolean }) {
  return (
    <span
      className={cx(styles.container, getSizeClass(size), getClassForUserType(userType), className)}
      title={showTitle ? userName || undefined : undefined}
      aria-label={userName || undefined}
      role="img"
    >
      {!!highlight && <div className={cx(styles.highlight, getHighlightClass(highlight))} />}

      <div className={styles.wrapper}>
        {src ? (
          <Image src={src} fill alt="" aria-hidden />
        ) : (
          <GeneratedAvatar userId={userId} userInitials={userInitials} userType={userType} />
        )}
      </div>

      {userRole === 'admin' ||
        (userRole === 'admin_compliance' && (
          <div className={styles.adminBadge}>
            <Image src="/avatar-admin-badge.svg" fill alt="" aria-hidden />
          </div>
        ))}

      {required && (
        <div className={styles.requiredBadge}>
          <Image src="/avatar-required-badge.svg" fill alt="" aria-hidden />
        </div>
      )}
    </span>
  );
}

function GeneratedAvatar({ userInitials, userId, userType }: Pick<Props, 'userInitials' | 'userId' | 'userType'>) {
  const style = useMemo((): React.CSSProperties => {
    if (userType === 'api') {
      return {};
    }

    const generator = new MersenneTwister(getSeedFromAnything({ value: userId }));
    const idx = Math.floor(colors.length * generator.random());
    return { backgroundColor: colors[idx], color: textColors[idx] };
  }, [userId, userType]);

  return (
    <div className={styles.generatedAvatar} style={style} aria-hidden>
      <span className={styles.generatedAvatarText}>{userInitials?.slice(0, 2)}</span>
    </div>
  );
}

function getClassForUserType(type: Props['userType']) {
  switch (type) {
    case 'human': {
      return styles.userTypeHuman;
    }
    case 'api': {
      return styles.userTypeApi;
    }
  }
}

function getSizeClass(size: Props['size']) {
  switch (size) {
    case 'huge': {
      return styles.sizeHuge;
    }
    case 'large': {
      return styles.sizeLarge;
    }
    case 'navBar': {
      return styles.sizeNavBar;
    }
    case 'small': {
      return styles.sizeSmall;
    }
  }
}

function getHighlightClass(highlight: Props['highlight']) {
  switch (highlight) {
    case 'danger': {
      return styles.highlightDanger;
    }
    case 'dimmed': {
      return styles.highlightDimmed;
    }
    case 'primary': {
      return styles.highlightPrimary;
    }
    default: {
      return null;
    }
  }
}

import { BigNumber } from 'bignumber.js';

export function crypto({
  locale,
  value: valueParam,
  currency,
  round,
}: {
  locale: string;
  value: BigNumber.Value | bigint;
  currency?: string | null | undefined;
  round: boolean;
}) {
  const suffix = currency ? `\u00A0${currency}` : '';
  const separator = getDecimalSeparator({ locale });
  const bigNumber = typeof valueParam === 'bigint' ? new BigNumber(valueParam.toString(10)) : new BigNumber(valueParam);

  if (round) {
    const formatted = Intl.NumberFormat(locale, { maximumFractionDigits: 4, minimumFractionDigits: 2 }).format(
      // @ts-expect-error this works perfectly on the browser, but still has precision loss on Node.js, unfortunately
      bigNumber.toString(),
    );
    const formattedEn = Intl.NumberFormat('en-US', { maximumFractionDigits: 4 }).format(
      // @ts-expect-error this works perfectly on the browser, but still has precision loss on Node.js, unfortunately
      bigNumber.toString(),
    );
    const isActuallyRounded = formattedEn.replaceAll(',', '') !== bigNumber.toString(10);

    return [isActuallyRounded ? '~' : '', formatted, suffix].join('');
  } else {
    const intFormatted = Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(
      // @ts-expect-error this works perfectly on the browser, but still has precision loss on Node.js, unfortunately
      bigNumber.integerValue(BigNumber.ROUND_FLOOR).toString(),
    );
    const decimals = getDecimals(bigNumber);

    return [intFormatted, separator, decimals.padEnd(2, '0'), suffix].join('');
  }
}

function getDecimals(value: BigNumber): string {
  const str = value.toFixed();
  const dotIndex = str.indexOf('.');
  if (dotIndex < 0) {
    return '';
  }

  return str.slice(dotIndex + 1);
}

function getDecimalSeparator({ locale }: { locale: string }) {
  const result = Intl.NumberFormat(locale, { style: 'decimal' })
    .formatToParts(1.1)
    .find((it) => it.type === 'decimal');

  if (!result) {
    return '.';
  }

  return result.value;
}
